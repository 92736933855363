<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div>
      <section :class="[ isFullScreen ? 'container-is-fs' : '']">
        <pdf-renderer
          :isDocument="true"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import PDFRenderer from './PDFRenderer.vue'

export default {
  components: {
    'pdf-renderer': PDFRenderer
  },
  props: {
    document: {
      required: false
    }
  },
  data () {
    return {
      tryRenderingIE: false,
      isLoaded: {},
      shouldShowLoading: true,
      institution: null,
      id: null,
      documentFromId: null,
      canViewDocument: false,
      numPages: null,
      src: null,
      rotate: 0,
      isPrinting: false,
      isEdgeOrSafari: false,
      isRotating: false,
      ua: '',
      isLoading: false,
      isLandScape: false,
      isFullScreen: false,
      zoomLevel: 100
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    pageTitle () {
      let name = ''

      if (this.document) {
        name = this.document !== undefined && this.document !== null ? this.document.name : 'Document'
      } else if (this.documentFromId) {
        name = this.documentFromId !== undefined && this.documentFromId !== null ? this.documentFromId.name : 'Document'
      }

      if (this.institution) {
        return (name + ' - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      }

      return name
    },
    extension () {
      if (this.document && this.document.extension.toLowerCase() !== '.pdf') {
        return this.document.extension.toLocaleUpperCase()
      }

      if (this.documentFromId && this.documentFromId.extension.toLowerCase() !== '.pdf') {
        return this.documentFromId.extension.toLocaleUpperCase()
      }

      return ''
    },
    isStaff () {
      return ['staff', 'manager'].includes(this.clientSession.userRole.toLowerCase())
    }
  }
}
</script>
<style scoped>
#loading-container {
  background-color: white !important;
}
</style><style scoped>

@media  (max-width: 850px) {
  .heading {
    text-align: right;
  }

  .heading {
    width: 80%!important;
    transform: translateX(0px);
  }

  #body-content-area-all {
    overflow-y: hidden;
    border: 1px solid rgb(237, 237, 237);
    padding: 0rem!important;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: whitesmoke!important;
    overflow: hidden!important;
  }

  #pagelayout {
    padding: 0px!important;
    background-color: whitesmoke;
    width: 100%;
  }
}

.is-rotating {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 5%;
    }

    50% {
      opacity: 30%;
    }

    100% {
        opacity: 5%;
    }
}

@media  screen and (max-width: 799px) {
  .heading {
    width: 80%!important;
    transform: translateX(0px);
  }

  #body-content-area-all {
    border: 1px solid rgb(237, 237, 237);
    padding: 0rem!important;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: whitesmoke!important;
    overflow-y: hidden;
  }

  #pagelayout {
    padding: 0px!important;
    background-color: whitesmoke;
  }
}

.-heading {
  position: relative;
  width: 90%!important;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.pdf-page {
  height: calc(100% - 42px);
}

.pdf-controls {
  position: absolute;
  left: 0;
  top: 0;

  padding: .25rem .5rem;

  display: flex;
  height: 40px;

  align-items: center;
}

.pdf-controls > button[disabled], .pdf-controls > a[disabled] {
  opacity: .5;
  color: #b0b0b0!important;
  border: 1px solid #b0b0b0!important;
  background: rgba(0,0,0,0.05);
  cursor: pointer;
}

.pdf-controls > button, .pdf-controls > a {
  position: sticky;

  margin: 0;
  margin-right: .5rem;
  margin-top: .175rem;

  border: 1px solid #b0b0b0;
  border-radius: .25rem;

  color: #b0b0b0;

  padding: .125rem!important;
  padding-right: .25rem!important;

  cursor: pointer;

  background: transparent;
}

.pdf-controls > button:hover, .pdf-controls > a:hover {
  background-color: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.5);

  transition: all .25s ease-in-out;
}

.report-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;

  /* overflow: auto; */
  height: 100%!important;
  max-width: 1000px;

  margin: 0 auto;

  background-color: whitesmoke;
  border: 1px solid rgba(0,0,0,0.1);
  border-top: 0px;
  z-index: 100;
}

.pdf-container {
  height: calc(100vh - 92px - 62px - 42.5px - 35px)!important;
  max-width: 800px;
  margin: 0 auto;
  background-color: whitesmoke!important;
}
.report-container:first-of-type {
  border-top: 1px solid rgba(0,0,0,0.1);
}

.k-master-row > td:first-of-type {
  width: 100%!important;
}

.k-master-row > td:first-of-type > a {
  width: 100%!important;
}

.report-container__landscape {
  aspect-ratio: 22/17;
}

.report-container__portrait {
  aspect-ratio: 17/22;
}

.report-container > div, .report-container > span  > canvas {
  width: 100%!important;
  height: calc(100%);
  padding: 0rem;
}

.label {
  display: none!important;
  position: sticky;
  top: 0;
  left: 0;

  height: 30px!important;
  width: 100%!important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px!important;
  color: black;

  background-color: #f5f5f5;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
}

.report-container canvas, .report-container .pdf {
  width: 100%!important;
  height: 100%!important;
  padding: 0rem!important;
}

.container-to-the-report-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: whitesmoke!important;
}

.container-to-the-report-container > .pdf {
  /* border-bottom: 1px solid #dcdcdc; */
  margin-bottom: 10px;
}

.container-is-fs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.container-is-fs .-heading {
  width: 100%!important;
  transform: translateX(0px);
  margin-top: 0rem!important;
}

.container-is-fs #body-content-area-all {
  width: 100vw!important;
  border: 1px solid rgb(237, 237, 237);
  padding: 0rem!important;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #f5f5f5!important;
  overflow-y: hidden!important;
  height: 100vh!important;
  min-height: 100vh!important;
}

.pdf {

}

.container-is-fs .pdf-container {
  height: calc(100vh - 55px)!important;
  max-width: 1500px;
}

#pagelayout {
  height: calc(100% - 1rem)!important;
  overflow: scroll!important;
  background-color: rgba(245, 245, 245, 0.719)!important;
}
.zoom-1x  > div {
  height: 100%;
  width: 100%;
}

.zoom-2x  > div {
  height: calc(100% * 1.5)!important;
  width: calc(100% * 1.5)!important;
}

.zoom-3x  > div {
  height: calc(100% * 2);
  width: calc(100% * 2);
}

.zoom-4x > div {
  height: calc(100% * 3);
  width: calc(100% * 3);
}

[zoom="125"] {
  min-width: 100%!important;
  width: 100%!important;
}

[zoom="150"] {
  min-width: 110%!important;
  width: 110%!important;
}

[zoom="175"] {
  min-width: 120%!important;
  width: 120%!important;
}

[zoom="200"] {
  min-width: 130%!important;
  width: 130%!important;
}

[zoom="50"] {
  min-width: 35%!important;
  max-width: 35%!important;
  width: 35%!important;
}

[zoom="75"] {
  min-width: 50%!important;
  max-width: 50%!important;
  width: 50%!important;
}

.-pdf {
  height: auto;
  width: 100%;
  background-color: white;

  border: 2px solid rgb(237, 237, 237);
  border-radius: .25rem;
}

canvas {
  border: 1px solid black!important;
}

.-pdf > canvas {
  max-height: 100%!important;
}

#app-body-area[activelayout="client-facing"] #body-content-area-all[pdfkit] {
  height: calc(100vh - 2rem - 86px - 92px - 1rem)!important;
  margin: 0 auto;
  width: 90%!important;
}

#app-body-area[activelayout="administrative"] #body-content-area-all[pdfkit] {
  height: calc(100vh - 2rem - 86px - 92px - 1rem)!important;
  margin: 0 auto;
  width: 90%!important;
}

.modal-background {
  z-index: 40;
}

.modal-card {
  z-index: 50;
}
#app-body-area[activelayout="osr-facing"] #body-content-area-all[pdfkit] {
  height: calc(100vh - 52px - 86px - 78px)!important;
  margin: 0 auto;
  width: 90%!important;
}

.pagelayout {
  overflow: auto!important;
}

button.print, button.download, a.download, a.print {
  max-width: 30px;
  max-height: 30px;
  width: 100%;
  aspect-ratio: 1/1;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
